/*!
 * Simple Age Verification (https://github.com/Herudea/age-verification))
 */

document.addEventListener('DOMContentLoaded', (event) => {

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


	var modal_content, modal_screen;

	// Start Working ASAP.
	$(document).ready(function() {
		av_legality_check();
	});

	av_legality_check = function() {
		if (getCookie('is_legal') == "yes") {
			// legal!
			// Do nothing?
		} else {
			av_showmodal();

			// Make sure the prompt stays in the middle.
			$(window).on('resize', av_positionPrompt);
		}
	};

	av_showmodal = function() {
		modal_screen = $('<div id="modal_screen"></div>');
		modal_content = $('<div id="modal_content" style="display:none"></div>');
		var modal_content_wrapper = $('<div id="modal_content_wrapper" class="content_wrapper"></div>');
		var modal_regret_wrapper = $('<div id="modal_regret_wrapper" class="content_wrapper" style="display:none;"></div>');

		// Question Content
		var content_heading = $('<h2>Sind Sie älter als 18?</h2>');
		var content_buttons = $('<nav><ul><li><a href="#nothing" class="av_btn av_go" rel="yes">Ja</a></li><li><a href="#nothing" class="av_btn av_no" rel="no">Nein</a></li></nav>');
		var content_text = $('<p>Um auf die Webseite zu gelangen, müssen Sie mindestens 18 Jahre alt sein</p>');

		// Regret Content
		var regret_heading = $('<h2>Entschuldigung!</h2>');
		var regret_buttons = $('<nav><small>Ich habe den falschen Button gedrückt!</small> <ul><li><a href="#nothing" class="av_btn av_go" rel="yes">Ich bin älter als 18!</a></li></ul></nav>');
		var regret_text = $('<p>Sie müssen 18 Jahre oder älter sein, um diesen Shop nutzen zu können.</p>');

		modal_content_wrapper.append(content_heading, content_buttons, content_text);
		modal_regret_wrapper.append(regret_heading, regret_buttons, regret_text);
		modal_content.append(modal_content_wrapper, modal_regret_wrapper);

		// Append the prompt to the end of the document
		$('body').append(modal_screen, modal_content);

		// Center the box
		av_positionPrompt();

		modal_content.find('a.av_btn').on('click', av_setCookie);
	};

	av_setCookie = function(e) {
		e.preventDefault();

		var is_legal = $(e.currentTarget).attr('rel');

		setCookie('is_legal', is_legal, 30);

		if (is_legal == "yes") {
			av_closeModal();
			$(window).off('resize');
		} else {
			av_showRegret();
		}
	};

	av_closeModal = function() {
		modal_content.fadeOut();
		modal_screen.fadeOut();
	};

	av_showRegret = function() {
		modal_screen.addClass('nope');
		modal_content.find('#modal_content_wrapper').hide();
		modal_content.find('#modal_regret_wrapper').show();
	};

	av_positionPrompt = function() {
		var top = ($(window).outerHeight() - $('#modal_content').outerHeight()) / 2;
		var left = ($(window).outerWidth() - $('#modal_content').outerWidth()) / 2;
		modal_content.css({
			'top': top,
			'left': left
		});

		if (modal_content.is(':hidden') && (getCookie('is_legal') != "yes")) {
			modal_content.fadeIn('slow')
		}
	};

})
